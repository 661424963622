<template>
  <BCard class="container">
    <div class="d-flex">
      <BButton
        variant="primary"
        size="sm"
        class="rounded-lg mr-2"
        to="/notification"
      >
        <b-icon-chevron-left />
      </BButton>
      <span class="text-[24px] font-semibold my-auto text-black">Notification Bar</span>
    </div>
    <ValidationObserver #default="{invalid}">
      <BRow class="my-3">
        <BCol md="6">
          <ValidationProvider
            #default="{ errors }"
            name="Judul"
            rules="required"
          >
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Judul</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <BFormInput
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Masukan judul notifikasi kamu"
                class="mb-1"
              />
              <small class="text-primary">{{ errors[0] }}</small>
            </BFormGroup>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="Deskripsi"
            rules="required"
          >
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Deskripsi</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <BFormTextarea
                v-model="desc"
                :state="errors.length > 0 ? false : null"
                placeholder="Masukan deskripsi kamu"
                class="mb-1"
                rows="4"
              />
              <small class="text-primary">{{ errors[0] }}</small>
            </BFormGroup>
          </ValidationProvider>
          <BRow>
            <BCol lg="6">
              <BFormGroup
                class="mr-2 text-lg"
                label="Link Url"
              >
                <BFormInput
                  v-model="link"
                  placeholder="Masukan link URL"
                  class="mb-1"
                />
              </BFormGroup>
            </BCol>
            <BCol lg="6">
              <ValidationProvider
                #default="{ errors }"
                name="Warna"
                rules="required"
              >
                <BFormGroup>
                  <label
                    class="text-lg"
                    for="title"
                  >Warna</label>
                  <div
                    class="p-1 border-[1px] border-[#E2E2E2] rounded-[49px]"
                    style="max-width: 235px"
                  >
                    <button
                      v-for="items in colorOptions"
                      :id="items.id"
                      :key="items.id"
                      :state="items.active ? true : null"
                      class="color-options rounded-full ml-1"
                      :class="items.active ? 'active' : ''"
                      @click="setColors(items)"
                    />
                  </div>
                  <small class="text-primary">{{ errors[0] }}</small>
                </BFormGroup>
              </ValidationProvider>
            </BCol>
          </BRow>
          <BRow class="mt-2">
            <ValidationProvider
              #default="{ errors }"
              label="Platform"
              name="Platform"
              rules="required"
            >
              <BCol
                lg="12"
                class="flex items-center"
              >
                <div
                  v-for="service in platform"
                  :key="service.id"
                  class="flex items-center mr-1"
                >
                  <BFormCheckbox v-model="service.onCheck" />
                  <img
                    :src="service.img_url"
                    alt="Komerce"
                    class="w-[100px]"
                  >
                </div>
                <small class="text-primary">{{ errors[0] }}</small>
              </BCol>
            </ValidationProvider>
          </BRow>
        </BCol>
        <BCol md="6">
          <div class="d-flex justify-content-end mb-1">
            <BButton
              :variant="invalid ? 'outline-secondary' : 'outline-primary'"
              class="rounded-lg"
              :disabled="invalid || disableBtn || checkStatus() || !color"
              @click="submit('save')"
            >
              Draft
            </BButton>
            <BButton
              :variant="invalid ? 'secondary' : 'primary'"
              class="rounded-lg ml-1"
              :disabled="invalid || disableBtn || checkStatus() || !color"
              @click="submit('submit')"
            >
              Publish
            </BButton>
          </div>
          <div class="bg-[#F8F8F8] d-flex py-2">
            <img
              v-if="colorOptions[0].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-success.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[1].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-warning.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[2].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-danger.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[3].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-info.svg"
              alt="Komerce"
              class="mx-auto"
            >
          </div>
        </BCol>
      </BRow>
    </ValidationObserver>
  </BCard>
</template>
<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      colorOptions: [
        { id: 'colors-1', value: '#B6FBC9', active: false },
        { id: 'colors-2', value: '#FFDF82', active: false },
        { id: 'colors-3', value: '#F86363', active: false },
        { id: 'colors-4', value: '#BEE6FC', active: false },
      ],
      title: null,
      desc: null,
      link: null,
      color: null,
      platform: [
        {
          id: 1,
          name: 'komerce',
          onCheck: false,
          img_url: 'https://storage.googleapis.com/komerce/core/brand.png',
        },
        {
          id: 2,
          name: 'affiliate',
          onCheck: false,
          img_url: 'https://storage.googleapis.com/komerce/assets/Affiliate/Komerce%20Affiliate.png',
        },
      ],
      required,
      disableBtn: false,
    }
  },
  methods: {
    setColors(items) {
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.colorOptions.length; x++) {
        if (this.colorOptions[x].id === items.id) {
          this.colorOptions[x].active = true
        } else {
          this.colorOptions[x].active = false
        }
      }
      this.color = items.value
    },
    async submit(action) {
      this.disableBtn = true
      const url = '/v1/admin/notification-bar'
      const serviceType = this.platform.filter(item => item.onCheck)
      const serviceName = serviceType.map(item => item.name)

      const payload = {
        action,
        title: this.title,
        desc: this.desc,
        link: this.link,
        color: this.color,
        service_type: serviceName,
      }

      const post = async () => {
        await komshipAxiosIns.post(url, payload)
          .then(() => {
            this.$router.push('/notification')
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'Notifikasi berhasil dibuat',
                variant: 'success',
              },
            }, { timeout: 1000 })

            for (let x = 0; x < this.platform.length; x += 1) {
              this.platform[x].onCheck = false
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            }, { timeout: 1000 })
          })
      }
      if (action === 'submit') {
        this.$swal({
          title: '<span class="font-medium text-[16px]">Kamu yakin ingin mempublish notifikasi ini?</span>',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.svg'),
          confirmButtonText: 'Publish',
          confirmButtonClass: 'btn btn-primary rounded-lg',
          showCancelButton: true,
          cancelButtonText: 'Batal',
          cancelButtonClass: 'btn btn-outline-primary bg-white text-primary rounded-lg',
        }).then(then => {
          if (then.isConfirmed) {
            post()
          } else {
            this.disableBtn = false
          }
        })
      } else {
        post()
      }
    },
    checkStatus() {
      const test = this.platform.filter(item => item.onCheck)
      if (test.length === 0) {
        return true
      }
      return false
    },
  },
}
</script>
<style lang="scss" src="./notification.scss" />
